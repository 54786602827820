// @ts-ignore
import SVGInject from '@iconfu/svg-inject'

const UNKNOWN_STATUS = 'unknown'
const HOME_ID_ATTR = 'data-home-id'
const HOME_STATUS_ATTR = 'data-home-status'
const HOME_CLOSE_TRIGGER_ATTR = 'data-home-close-trigger'
const HOME_STATE_ATTR = 'data-home-state'

declare global {
    interface Window {
        fsAttributes: any
    }
}

export type StatusOptions = {
    outlineHoverColor?: string,
    color?: string,
    clickable?: boolean
}

export enum HomeState {
    OPEN = 'open'
}

class Selector {
    private allHomeIDs: Set<string> = new Set()
    private readonly clickableStatuses: Set<string>

    constructor(
        private readonly overlaySelectors: string[],
        clickableStatuses: string[],
        private readonly rememberOpenState: boolean,
        private readonly onInitialized?: () => void,
        private readonly onHomeOpened?: (id: string) => void,
    ) {
        this.clickableStatuses = new Set(clickableStatuses)
    }

    private markAllHomesClosed() {
        if (!this.rememberOpenState) {
            return
        }

        const selector = `[${HOME_CLOSE_TRIGGER_ATTR}][${HOME_STATE_ATTR}="${HomeState.OPEN}"]`
        const openCloseTriggerEls = document.querySelectorAll<HTMLElement>(selector)
        openCloseTriggerEls.forEach(el => {
            const id = el.getAttribute(HOME_CLOSE_TRIGGER_ATTR)
            if (!id) {
                return
            }

            this.markHomeClosed(id)
        })
    }

    private markElOpen(el: HTMLElement) {
        if (!this.rememberOpenState) {
            return
        }

        el.setAttribute(HOME_STATE_ATTR, HomeState.OPEN)
    }

    private markHomeOpen(id: string) {
        if (!this.rememberOpenState) {
            return
        }

        this.markAllHomesClosed()

        const els = document.querySelectorAll<HTMLElement>(`#${id}, [${HOME_CLOSE_TRIGGER_ATTR}="${id}"]`)
        els.forEach(el => this.markElOpen(el))

        if (this.onHomeOpened) {
            this.onHomeOpened(id)
        }
    }

    private markElClosed(el: HTMLElement) {
        if (!this.rememberOpenState) {
            return
        }

        const closeEl = el.closest<HTMLElement>(`[${HOME_CLOSE_TRIGGER_ATTR}]`)
        const stateEl = closeEl || el

        const isOpen = stateEl.getAttribute(HOME_STATE_ATTR) === HomeState.OPEN

        if (!isOpen) {
            return
        }

        stateEl.removeAttribute(HOME_STATE_ATTR)

        if (Boolean(closeEl)) {
            stateEl.click()
        }
    }

    private markHomeClosed(id: string) {
        if (!this.rememberOpenState) {
            return
        }

        const els = document.querySelectorAll<HTMLElement>(`#${id}, [${HOME_CLOSE_TRIGGER_ATTR}="${id}"]`)
        els.forEach(el => this.markElClosed(el))
    }

    public init() {
        this.allHomeIDs = new Set(this.findAllHomeIDs())
        this.render()
        this.injectSVGs()

        window.fsAttributes = window.fsAttributes || []
        window.fsAttributes.push([
            'cmsfilter',
            (filterInstances: any[]) => {
                filterInstances.forEach(instance => {
                    instance.listInstance.on('renderitems', () => {
                        this.markAllHomesClosed()
                        this.render()
                    })
                })
            },
        ])

        document.addEventListener<'click'>('click', e => {
            const target = e?.target as null | HTMLElement
            const closeID = target?.closest(`[${HOME_CLOSE_TRIGGER_ATTR}]`)?.getAttribute(HOME_CLOSE_TRIGGER_ATTR)

            if (!closeID) {
                return
            }

            this.markHomeClosed(closeID)
        })
    }

    private injectSVGs() {
        SVGInject(document.querySelectorAll(this.overlaySelectors.join(',')), {
            makeIdsUnique: false,
            onAllFinish: () => {
                this.render()
                this.initOverlayClickListeners()

                if (this.onInitialized) {
                    this.onInitialized()
                }
            }
        })
    }

    private initOverlayClickListeners() {
        for (const selector of this.overlaySelectors) {
            const overlayEls = document.querySelectorAll(selector)

            overlayEls.forEach(el => {

                el?.addEventListener('click', e => {
                    const target: HTMLElement | null = e.target as HTMLElement | null

                    if (!target) {
                        return
                    }

                    const overlayHomeEl = target.closest<HTMLElement>(`[${HOME_STATUS_ATTR}]`)
                    const status = overlayHomeEl?.dataset.homeStatus
                    const id = overlayHomeEl?.id

                    if (!id || !status || !this.clickableStatuses.has(status)) {
                        return
                    }

                    document.querySelector<HTMLElement>(`[${HOME_ID_ATTR}="${id}"]`)?.click()

                    this.markHomeOpen(id)
                    this.render()
                })
            })
        }
    }

    public render() {
        this.updateOverlayStatuses(this.findAllHomeStatuses())
    }

    private findAllHomeStatuses(): Record<string, string> {
        const statuses: Record<string, string> = {}

        for (const id of this.allHomeIDs) {
            const homeEl = document.querySelector<HTMLElement>(`[${HOME_ID_ATTR}="${id}"]`)

            const status = homeEl?.dataset.homeStatus

            if (status) {
                statuses[id] = status
            }
        }

        return statuses
    }

    private findAllHomeIDs(): string[] {
        const allHomeIDs: string[] = []

        const allHomeEls = document.querySelectorAll<HTMLElement>(`[${HOME_ID_ATTR}]`)
        allHomeEls.forEach(homeEl => {
            const id = homeEl.dataset.homeId

            if (id) {
                allHomeIDs.push(id)
            }
        })

        return allHomeIDs
    }

    private updateOverlayStatuses(newStatuses: Record<string, string>) {
        const overlayHomeEls = this.getAllOverlayHomeEls()

        for (const homeID of this.allHomeIDs) {
            const overlayHomeEl = overlayHomeEls[homeID]

            if (!overlayHomeEl) {
                continue
            }

            overlayHomeEl.dataset.homeStatus = newStatuses[homeID] || UNKNOWN_STATUS
        }
    }

    private getAllOverlayHomeEls(): Record<string, HTMLElement> {
        const elements: Record<string, HTMLElement> = {}

        for (const id of this.allHomeIDs) {
            const homeEl = document.getElementById(id)

            if (homeEl) {
                elements[id] = homeEl
            }
        }

        return elements
    }
}

const injectCSS = (overlaySelectors: string[], statuses: Record<string, StatusOptions>) => {
    const s = (selector: string) => overlaySelectors.map(overlaySelector => `${overlaySelector} ${selector}`).join(',')

    let css = `
        ${s(`[${HOME_STATUS_ATTR}="${UNKNOWN_STATUS}"]`)} {
            display: none;
        }
        
        ${s(`[${HOME_STATUS_ATTR}] polygon`)}, ${s(`[${HOME_STATUS_ATTR}] path`)}, ${s(`[${HOME_STATUS_ATTR}] rect`)} {
            transition: all 0.1s ease-in-out;
        }
    `

    for (const status in statuses) {
        const options = statuses[status]!

        if (options.clickable) {
            css += `
                ${s(`[${HOME_STATUS_ATTR}="${status}"]:hover`)} {
                    cursor: pointer;
                }
            `

            if (options.outlineHoverColor) {
                css += `
                    ${s(`[${HOME_STATUS_ATTR}="${status}"]:hover polygon`)}, ${s(`[${HOME_STATUS_ATTR}="${status}"]:hover path`)}, ${s(`[${HOME_STATUS_ATTR}="${status}"]:hover rect`)},
                    ${s(`[${HOME_STATUS_ATTR}="${status}"][${HOME_STATE_ATTR}="${HomeState.OPEN}"] polygon`)}, ${s(`[${HOME_STATUS_ATTR}="${status}"][${HOME_STATE_ATTR}="${HomeState.OPEN}"] path`)}, ${s(`[${HOME_STATUS_ATTR}="${status}"][${HOME_STATE_ATTR}="${HomeState.OPEN}"] rect`)} {
                        fill: ${options.outlineHoverColor};
                    }
                `
            }
        } else {
            css += `
                ${s(`[${HOME_STATUS_ATTR}="${status}"] text`)} {
                    display: none;
                }
            `
        }

        if (options.color) {
            css += `
                ${s(`[${HOME_STATUS_ATTR}="${status}"] circle`)}, ${s(`[${HOME_STATUS_ATTR}="${status}"] ellipse`)} {
                    fill: ${options.color};
                }
            `
        }
    }

    const style = document.createElement('style')
    style.textContent = css
    document.head.append(style)
}

const unavailableStatusOptions = {
    color: '#df96aa',
}

export const defaultStatuses: Record<string, StatusOptions> = Object.freeze({
    'Ledig': {
        outlineHoverColor: 'rgba(255, 255, 255, 0.5)',
        clickable: true,
    },
    'Reserveret': {
        color: '#FFD95C',
    },
    'Solgt': unavailableStatusOptions,
    'Udlejet': unavailableStatusOptions,
})

export const createHomesSelector = (
    options: {
        overlaySelectors: string[],
        statuses?: Record<string, StatusOptions>,
        injectCSS?: boolean,
        rememberOpenState?: boolean,
        onInitialized?: () => void,
        onHomeOpened?: (id: string) => void,
    }
) => {
    const statuses = options.statuses || defaultStatuses
    const clickableStatuses = Object.keys(statuses).filter(status => statuses[status]?.clickable)

    const shouldInjectCSS = options.injectCSS || true
    if (shouldInjectCSS) {
        injectCSS(options.overlaySelectors, statuses)
    }

    const selector = new Selector(
        options.overlaySelectors,
        clickableStatuses,
        typeof options.rememberOpenState === 'undefined' ? true : options.rememberOpenState,
        options.onInitialized,
        options.onHomeOpened,
    )

    selector.init()

    return selector
}