import { AllRates } from './'

const common = {
    unregisteredAssetLimit: 100000,

    assetLimitLowRate: .1,
    assetLimitHighRate: .2,

    areaSupportedBase: 65,
    areaSupportedPerAdditionalResident: 20,

    housingExpenseRateIncreasePerChild: .05,

    benefitUpperLimitRateIncreaseAtLeastFourChildren: .25,
}

export const boligydelse: AllRates = {
    ...common,
    assetLimitLow: 973800,
    assetLimitHigh: 1947700,

    housingExpenseBaseLimit: 103800,
    housingExpenseBonus: 7800,
    housingExpenseHeatingOverRentDeduction: 99.5,
    housingExpenseHotWaterOverRentDeduction: 31.5,
    housingExpenseElectricityOverRentDeduction: 75,
    housingExpenseWaterChargeSupplement: 16.5,
    housingExpenseWaterDrainageChargeSupplement: 24,
    housingExpenseFullMaintenanceSupplement: 75,
    housingExpensePartialMaintenanceSupplement: 37.25,
    housingExpenseHeatingSupplement: 38,

    benefitHousingExpenseRate: .75,
    benefitHouseholdIncomeRate: .225,
    benefitHouseholdIncomeBaseLimit: 185000,
    benefitHouseholdIncomeIncreasePerChild: 48700,

    benefitBaseUpperLimit: 54756,
    benefitLowerLimit: 4020,
    benefitMinSelfPaymentRateOfIncome: .11,
    benefitMinSelfPayment: 19600,
}

export const boligsikring: AllRates = {
    ...common,
    assetLimitLow: 855800,
    assetLimitHigh: 1711800,

    housingExpenseBaseLimit: 91200,
    housingExpenseHeatingOverRentDeduction: 87.5,
    housingExpenseHotWaterOverRentDeduction: 27.75,
    housingExpenseElectricityOverRentDeduction: 66,
    housingExpenseWaterChargeSupplement: 14.5,
    housingExpenseWaterDrainageChargeSupplement: 21,
    housingExpenseFullMaintenanceSupplement: 66,
    housingExpensePartialMaintenanceSupplement: 32.75,
    housingExpenseHeatingSupplement: 33.5,

    benefitHousingExpenseRate: .6,
    benefitHouseholdIncomeRate: .18,
    benefitHouseholdIncomeBaseLimit: 162600,
    benefitHouseholdIncomeIncreasePerChild: 42800,

    benefitBaseUpperLimit: 48120,
    benefitLowerLimit: 3528,
    benefitMaxRateOfExpenseNoChildren: .15,
    benefitMinSelfPayment: 27400,
}