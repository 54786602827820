import * as allRates from './rates'
import type { Alpine as AlpineType } from 'alpinejs'

declare global {
    interface Window {
        Alpine: AlpineType
    }
}

declare var Alpine: AlpineType

/**
 * [1]: https://www.aeldresagen.dk/viden-og-raadgivning/vaerd-at-vide/b/bolig/boligstoette/hvad-indgaar-i-beregning-af-boligstoette
 * [2]: https://www.boligstoette.dk/bos-selvbetjening/beregner/
 * [3]: https://www.retsinformation.dk/eli/lta/2018/1263
 * [4]: https://www.retsinformation.dk/eli/lta/2019/48
 *
 * householdAssets can be negative and if it is does not impact calculation according to [1].
 */

export type HouseholdIncomeRates = {
    unregisteredAssetLimit: number,
    assetLimitHigh: number,
    assetLimitHighRate: number,
    assetLimitLow: number,
    assetLimitLowRate: number,
}

export type HouseholdIncomeParams = {
    householdIncome: number,
    registeredHouseholdAssets: number,
    unregisteredHouseholdAssets: number,
}

export const calculateHouseholdIncome = (
    {
        unregisteredAssetLimit, // If unregistered assets exceed this limit they should be included otherwise not.
        assetLimitHigh,
        assetLimitHighRate, // This % should be added to household income if assets exceed assetLimitHigh.
        assetLimitLow,
        assetLimitLowRate,
    }: HouseholdIncomeRates,
    {
        householdIncome,
        registeredHouseholdAssets,
        unregisteredHouseholdAssets,
    }: HouseholdIncomeParams,
) => {
    // According to [1] and [3] but on [2] you have to enter it as one field (you always add unregistered), why?
    let householdAssets = registeredHouseholdAssets
    if (unregisteredHouseholdAssets > unregisteredAssetLimit) {
        householdAssets += unregisteredHouseholdAssets
    }

    if (householdAssets > assetLimitHigh) {
        // Add for the low limit first and then the high.
        householdIncome += (assetLimitHigh - assetLimitLow) * assetLimitLowRate
        householdIncome += assetLimitHighRate * (householdAssets - assetLimitHigh)
    } else if (householdAssets > assetLimitLow) {
        householdIncome += assetLimitLowRate * (householdAssets - assetLimitLow)
    }

    return householdIncome
}

export type HousingExpenseRates = {
    areaSupportedBase: number,
    areaSupportedPerAdditionalResident: number,
    housingExpenseHeatingOverRentDeduction: number,
    housingExpenseHotWaterOverRentDeduction: number,
    housingExpenseElectricityOverRentDeduction: number,
    housingExpenseWaterChargeSupplement: number,
    housingExpenseWaterDrainageChargeSupplement: number,
    housingExpensePartialMaintenanceSupplement: number,
    housingExpenseFullMaintenanceSupplement: number,
    housingExpenseHeatingSupplement: number,
    housingExpenseBaseLimit: number,
    housingExpenseRateIncreasePerChild: number,
}

export type HousingExpenseParams = {
    rent: number,
    area: number,
    numAdultResidents: number,
    numChildResidents: number,
    paysForHeatingOverRent: boolean,
    paysForHotWaterOverRent: boolean,
    paysForElectricityOverRent: boolean,
    paysForWaterChargeOverRent: boolean,
    paysForWaterDrainageChargeOverRent: boolean,
    paintMaintenance?: 'partial' | 'full',
    otherMaintenance?: 'partial' | 'full',
    isHeatedWithElectricityGasOrHeatSupplyPlant: boolean,
}

export const calculateHousingExpenseBeforeBonus = (
    {
        areaSupportedBase,
        areaSupportedPerAdditionalResident,
        housingExpenseHeatingOverRentDeduction,
        housingExpenseHotWaterOverRentDeduction,
        housingExpenseElectricityOverRentDeduction,
        housingExpenseWaterChargeSupplement,
        housingExpenseWaterDrainageChargeSupplement,
        housingExpensePartialMaintenanceSupplement,
        housingExpenseFullMaintenanceSupplement,
        housingExpenseHeatingSupplement,
        housingExpenseBaseLimit,
        housingExpenseRateIncreasePerChild,
    }: HousingExpenseRates,
    {
        rent,
        area,
        numAdultResidents,
        numChildResidents,
        paysForHeatingOverRent,
        paysForHotWaterOverRent,
        paysForElectricityOverRent,
        paysForWaterChargeOverRent,
        paysForWaterDrainageChargeOverRent,
        paintMaintenance,
        otherMaintenance,
        isHeatedWithElectricityGasOrHeatSupplyPlant,
    }: HousingExpenseParams,
) => {
    const totalNumResidents = numAdultResidents + numChildResidents

    let housingExpense = rent

    // -1 for the first person (areaSupportedBase). totalNumResidents should always be at least 1 since the applicant is
    // counted.
    const maxAreaSupported = areaSupportedBase + areaSupportedPerAdditionalResident * (totalNumResidents - 1)

    // The housing expense must be reduced based on the fact that benefit is only given for areaSupportedBase m2 +
    // areaSupportedPerAdditionalResident m2 per additional resident. I.e. if areaSupportedBase is 65 m2, the area is
    // 100 m2 and it's a single resident, then benefit is only calculated based on 65% of the housing expense. If it's
    // two residents and areaSupportedPerAdditionalResident is 20 m2, then it's 85%.
    // Of course this should not exceed 100%.
    const housingExpenseReductionRate = Math.min(maxAreaSupported / area, 1)
    housingExpense *= housingExpenseReductionRate

    const totalAreaSupported = Math.min(maxAreaSupported, area)

    // Adjust housing expense based on utilities and how much maintenance the applicant is expected to do.
    if (paysForHeatingOverRent) {
        housingExpense -= totalAreaSupported * housingExpenseHeatingOverRentDeduction
    }

    if (paysForHotWaterOverRent) {
        housingExpense -= totalAreaSupported * housingExpenseHotWaterOverRentDeduction
    }

    if (paysForElectricityOverRent) {
        housingExpense -= totalAreaSupported * housingExpenseElectricityOverRentDeduction
    }

    if (!paysForWaterChargeOverRent) {
        housingExpense += totalAreaSupported * housingExpenseWaterChargeSupplement
    }

    if (!paysForWaterDrainageChargeOverRent) {
        housingExpense += totalAreaSupported * housingExpenseWaterDrainageChargeSupplement
    }

    if (paintMaintenance === 'partial') {
        housingExpense += totalAreaSupported * housingExpensePartialMaintenanceSupplement
    }

    if (paintMaintenance === 'full') {
        housingExpense += totalAreaSupported * housingExpenseFullMaintenanceSupplement
    }

    if (otherMaintenance === 'partial') {
        housingExpense += totalAreaSupported * housingExpensePartialMaintenanceSupplement
    }

    if (otherMaintenance === 'full') {
        housingExpense += totalAreaSupported * housingExpenseFullMaintenanceSupplement
    }

    if (isHeatedWithElectricityGasOrHeatSupplyPlant && !paysForHeatingOverRent) {
        housingExpense += totalAreaSupported * housingExpenseHeatingSupplement
    }

    let housingExpenseLimit = housingExpenseBaseLimit

    // Increase the limit by a % per child up to 4 children. Does not take into account if one of the residents is
    // severely physically disabled or is in constant care (should be increased by 50% in that case).
    if (numChildResidents > 0) {
        housingExpenseLimit += housingExpenseLimit * Math.min(numChildResidents, 4) * housingExpenseRateIncreasePerChild
    }

    return Math.min(housingExpenseLimit, housingExpense)
}

export type HousingBenefitRates = {
    housingExpenseBonus?: number,
    benefitHousingExpenseRate: number,
    benefitHouseholdIncomeBaseLimit: number,
    benefitHouseholdIncomeIncreasePerChild: number,
    benefitHouseholdIncomeRate: number,
    benefitMinSelfPayment: number,
    benefitMinSelfPaymentRateOfIncome?: number,
    benefitBaseUpperLimit: number,
    benefitUpperLimitRateIncreaseAtLeastFourChildren: number,
    benefitMaxRateOfExpenseNoChildren?: number,
    benefitLowerLimit: number,
}

export type HousingBenefitParams = {
    householdIncome: number,
    housingExpenseBeforeBonus: number,
    numChildResidents: number,
    isEarlyPensionerAccordingToNewRules: boolean,
}

export const calculateBenefit = (
    {
        housingExpenseBonus = 0, // For boligydelse this is added to the housing expense but not for boligsikring.
        benefitHousingExpenseRate,
        benefitHouseholdIncomeBaseLimit,
        benefitHouseholdIncomeIncreasePerChild,
        benefitHouseholdIncomeRate,
        benefitMinSelfPayment,
        benefitMinSelfPaymentRateOfIncome,
        benefitBaseUpperLimit,
        benefitUpperLimitRateIncreaseAtLeastFourChildren,
        benefitMaxRateOfExpenseNoChildren,
        benefitLowerLimit,
    }: HousingBenefitRates,
    {
        householdIncome,
        housingExpenseBeforeBonus,
        numChildResidents,
        isEarlyPensionerAccordingToNewRules,
    }: HousingBenefitParams,
) => {
    // This should be the last thing that's added. [4] § 21
    const housingExpense = housingExpenseBeforeBonus + housingExpenseBonus

    let benefit = housingExpense * benefitHousingExpenseRate

    let benefitHouseholdIncomeLimit = benefitHouseholdIncomeBaseLimit

    // The first child does not increase the income limit but children no. 2 - 4 do.
    const numAdditionalChildren = Math.min(4, Math.max(0, numChildResidents - 1))
    benefitHouseholdIncomeLimit += numAdditionalChildren * benefitHouseholdIncomeIncreasePerChild

    // If the household income is big enough a percentage of it minus the limit is subtracted from the benefit.
    if (householdIncome > benefitHouseholdIncomeLimit) {
        benefit -= (householdIncome - benefitHouseholdIncomeLimit) * benefitHouseholdIncomeRate
    }

    // There's a minimum amount of the expense that you have to pay yourself. In the case of boligydelse you also have
    // to pay a minimum rate of your income.
    let benefitMinSelfPaymentAmount = benefitMinSelfPayment
    if (benefitMinSelfPaymentRateOfIncome) {
        benefitMinSelfPaymentAmount = Math.max(benefitMinSelfPayment, benefitMinSelfPaymentRateOfIncome * householdIncome)
    }
    // If we end up paying less than we're allowed, we must subtract the difference from the benefit.
    const selfPayment = housingExpenseBeforeBonus - benefit
    if (selfPayment < benefitMinSelfPaymentAmount) {
        benefit -= benefitMinSelfPaymentAmount - selfPayment
    }

    // There's an upper limit.
    let benefitUpperLimit = benefitBaseUpperLimit
    if (numChildResidents >= 4) {
        benefitUpperLimit *= 1 + benefitUpperLimitRateIncreaseAtLeastFourChildren
    }
    benefit = Math.min(benefitUpperLimit, benefit)

    // [4] § 22 stk. 3. Benefit cannot exceed benefitMaxRateOfExpenseNoChildren of expense if there are no
    // child residents unless you're an early pensioner (or you're severely physically disabled or in constant care but
    // we don't take that into account).
    if (numChildResidents === 0 && benefitMaxRateOfExpenseNoChildren && !isEarlyPensionerAccordingToNewRules) {
        const benefitUpperLimitExpense = benefitMaxRateOfExpenseNoChildren * housingExpense

        benefit = Math.min(benefit, benefitUpperLimitExpense)
    }

    // There's a lower limit before the state wants to pay up.
    if (benefit < benefitLowerLimit) {
        return 0
    }

    return benefit
}


export type AllRates = HousingBenefitRates & HousingExpenseRates & HouseholdIncomeRates
export type AllParams = Omit<HouseholdIncomeParams & HousingBenefitParams & HousingExpenseParams, 'housingExpenseBeforeBonus'>

export const calculateHousingBenefit = (
    rates: AllRates,
    {
        isEarlyPensionerAccordingToNewRules,
        registeredHouseholdAssets,
        unregisteredHouseholdAssets,
        householdIncome,

        numAdultResidents,
        numChildResidents,

        rent,
        area,

        isHeatedWithElectricityGasOrHeatSupplyPlant,
        paysForHeatingOverRent,
        paysForHotWaterOverRent,
        paysForElectricityOverRent,
        paysForWaterChargeOverRent,
        paysForWaterDrainageChargeOverRent,
        paintMaintenance,
        otherMaintenance,
    }: AllParams
) => {
    householdIncome = calculateHouseholdIncome(
        rates,
        {
            householdIncome,
            registeredHouseholdAssets,
            unregisteredHouseholdAssets,
        },
    )

    const housingExpenseBeforeBonus = calculateHousingExpenseBeforeBonus(
        rates,
        {
            rent,
            area,
            numAdultResidents,
            numChildResidents,
            paysForHeatingOverRent,
            paysForHotWaterOverRent,
            paysForElectricityOverRent,
            paysForWaterChargeOverRent,
            paysForWaterDrainageChargeOverRent,
            paintMaintenance,
            otherMaintenance,
            isHeatedWithElectricityGasOrHeatSupplyPlant,
        },
    )

    return calculateBenefit(
        rates,
        {
            householdIncome,
            housingExpenseBeforeBonus,
            numChildResidents,
            isEarlyPensionerAccordingToNewRules,
        },
    )
}

export type PensionerParams = {
    isPensioner: boolean,
    pensionerType?: 'pensioner' | 'early-pensioner-pre-2003' | 'early-pensioner-post-2003'
}

const defaultParamsUnfrozen = {
    unregisteredHouseholdAssets: 0,

    isHeatedWithElectricityGasOrHeatSupplyPlant: true,
    paysForHeatingOverRent: false,
    paysForHotWaterOverRent: false,
    paysForElectricityOverRent: false,
    paysForWaterChargeOverRent: true,
    paysForWaterDrainageChargeOverRent: true,
    paintMaintenance: undefined,
    otherMaintenance: undefined,

    // Params specified in form.
    householdIncomePerMonth: 0,
    registeredHouseholdAssets: 0,
    numAdultResidents: 1,
    numChildResidents: 0,

    // Deprecated. Use pensionerValue instead.
    isPensioner: 'false',
    pensionerType: 'pensioner',

    // Can be false or one of the pensioner types.
    pensionerValue: 'false'
}

export const defaultParams = Object.freeze(defaultParamsUnfrozen)

const formParamKeys: (keyof typeof defaultParams)[] = [
    'householdIncomePerMonth',
    'registeredHouseholdAssets',
    'numAdultResidents',
    'numChildResidents',
    'pensionerValue'
]

export const createCalculator = (
    {
        isPensioner: isPensionerRaw,
        pensionerType,
        pensionerValue,

        householdIncomePerMonth,
        ...params
    }: typeof defaultParamsUnfrozen
) => {
    let rates: AllRates
    let isEarlyPensionerAccordingToNewRules = false

    // If pensionerValue is false (default) we use the old logic. Since isPensioner is false by default this means we'll
    // use non-pensioner rates when we use the new pensionerValue, and it's false.
    if (pensionerValue.toLowerCase() === 'false') {
        const isPensioner = isPensionerRaw.toLowerCase() === 'true'

        isEarlyPensionerAccordingToNewRules = isPensioner && pensionerType === 'early-pensioner-post-2003'

        rates = isPensioner && (pensionerType === 'pensioner' || pensionerType === 'early-pensioner-pre-2003')
            ? allRates.boligydelse
            : allRates.boligsikring
    } else {
        isEarlyPensionerAccordingToNewRules = pensionerValue === 'early-pensioner-post-2003'

        rates = pensionerValue === 'pensioner' || pensionerValue === 'early-pensioner-pre-2003'
            ? allRates.boligydelse
            : allRates.boligsikring
    }

    const householdIncome = householdIncomePerMonth * 12

    return (monthlyRent: number, area: number) => {
        const yearlyBenefit = calculateHousingBenefit(
            rates,
            {
                householdIncome,
                ...params,
                rent: monthlyRent * 12,
                area,
                isEarlyPensionerAccordingToNewRules,
            }
        )

        return Math.round(yearlyBenefit / 12)
    }
}

export const initData = (overrides: Partial<typeof defaultParamsUnfrozen> = {}) => {
    const data = {
        ...defaultParams,
        ...overrides,
        calculateHousingBenefit: null as null | ReturnType<typeof createCalculator>,
        update() {
            this.calculateHousingBenefit = createCalculator(data)
        },
        get hasHousingBenefit(): boolean {
            return Boolean(this.calculateHousingBenefit)
        }
    }

    return data
}

document.addEventListener('alpine:init', () => {
    function housingBenefit(overrides: Partial<typeof defaultParamsUnfrozen> = {}, persist = true) {
        const params = {
            ...defaultParams,
            ...overrides,
            hasHousingBenefit: false,
        }

        let enhancedParams: Record<string, unknown>
        if (persist && typeof this.$persist !== 'undefined') {
            enhancedParams = { ...params }

            for (const key of [...formParamKeys, 'hasHousingBenefit']) {
                const defaultValue = enhancedParams[key]
                enhancedParams[key] = this.$persist(defaultValue)
            }
        } else {
            enhancedParams = params
        }

        return {
            ...enhancedParams,
            init() {
                this.update(false)
            },
            update(flag = true) {
                const params = Object.fromEntries(Object.keys(defaultParams).map(key => {
                    return [key, this[key]]
                })) as typeof defaultParams

                this.calculateHousingBenefit = createCalculator(params)

                if (flag) {
                    this.hasHousingBenefit = true
                }
            },
            calculateHousingBenefit: null
        }
    }

    Alpine.data('housingBenefit', housingBenefit as any)
})