import * as selector from './selector'
import * as housingBenefit from './housing-benefit'

declare global {
    interface Window {
        HomesSelector: typeof selector;
        HousingBenefit: typeof housingBenefit
    }
}

window.HomesSelector = selector
window.HousingBenefit = housingBenefit

